


(function($){
	
	$.fn.rhBanner=function(options){
		
		var settings = $.extend({ 
			parallaxRatio:1.4,
			ajax:false,
			selector:'body'
		},options);
		
		// Detect css transform
		var cssTransform = (function(){
		    var prefixes = 'transform webkitTransform mozTransform oTransform msTransform'.split(' '), 
		    	cssTransform, 
		    	i = 0
				while( cssTransform === undefined ){ 
		       		cssTransform = document.createElement('div').style[prefixes[i]] != undefined ? prefixes[i] : undefined
			   		i++
				}
		     return cssTransform;
		    
		})() 
		
		// Detect request animation frame
		var rhParallaxScroll = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || window.oRequestAnimationFrame || function(callback){ window.setTimeout(callback, 1000/60) }
		
			// set up vars
			var rhParallaxElements=this,
			rhParallaxSize = rhParallaxElements.length,
			matrix = [],
			lastPosition,
			wHeight;
				
			// Pre calculate sizes to get better perfs
			function rhParallaxSizes(){
			    lastPosition = -1 // Force a recalculation
			    wHeight = window.innerHeight
			    var i = 0
			    
			    for (i =0; i<rhParallaxSize; i++){
			        matrix[i] = matrix[i] || { el: rhParallaxElements[i] }	       
			        
					var rect=matrix[i].el.getBoundingClientRect();
					matrix[i].height=matrix[i].el.offsetHeight;
					
					if (settings.ajax === false) {  
						matrix[i].start=rect.top+window.pageYOffset;
					} else {  
						matrix[i].start=rect.top;
					}

					matrix[i].stop=matrix[i].start+matrix[i].height;
					matrix[i].child=matrix[i].el.childNodes[1];
					
					matrix[i].child.style.height = matrix[i].height * settings.parallaxRatio + 'px';
					
					matrix[i].childHeight=matrix[i].child.offsetHeight;
					matrix[i].offset=matrix[i].childHeight-matrix[i].height;
			    }
			}
			
			$(window).resize(function() {
				rhParallaxSizes();
			});
			
			// Lets Do This
			rhParallaxSizes();
			loop();
			
			
		
		function setTop(m, t){
		    if (cssTransform)
		        m.child.style[cssTransform] = "translate3d(0, "+ t +"px,0)"
		    else
		        m.child.style["top"] = t
		}
		
		function loop(){ 
			
			var parallaxScrollPos;
			
			if (settings.ajax === false) { 
				parallaxScrollPos = window.pageYOffset;
			} else { 
				parallaxScrollPos = $(settings.selector).scrollTop();
			}
				
				// Avoid calculations if not needed
			    if (lastPosition == parallaxScrollPos) {
			        rhParallaxScroll(loop)
			        return false
			        
			    } else { 
				    
				    if (settings.ajax === false) {
						lastPosition = window.pageYOffset
					} else {
						lastPosition = $(settings.selector).scrollTop();
					}
								    
				    var i = 0
				    for (i =0; i<rhParallaxSize; i++){
				        // Is it visible right now?        
				       
				         if (lastPosition >= matrix[i].start - wHeight && lastPosition <= matrix[i].stop){
				          	// Do something				
							
							var beginScroll = matrix[i].start - wHeight,
							endScroll = matrix[i].stop,
							scrollDistance = endScroll - beginScroll,				
							
							// How far through scrollDistance have we travelled?
							ratio = (lastPosition - beginScroll) / scrollDistance,
							
							// Apply ratio to parallax taking into account height of inner and outer
							parallax = matrix[i].offset * ratio,
							
							// offset so that inner finishes at the top of outer
							parallaxTop = -matrix[i].offset + parallax;
							
							// Few! Lets do it...
							setTop(matrix[i], parallaxTop)          
			                 
			        	} 
			        
				    }
				   
				    rhParallaxScroll(loop)
				    
			    }
					    
		    
		} // end loop

	}

}(jQuery));	

	
	
	
	
	


 
	

	
