var $galleryOverlay = $('#galleryOverlay'),
		$galleryNext = $('#next'),
		$galleryPrev = $('#prev'),
		$closeGalleryOverlay = $('#closeGalleryOverlay'),
		$cardWrapper = $('#cardWrapper'),
		$card = $cardWrapper.find('.card'),
		$this,
		slideno
;

var $imageGallery = $('#imageGallery').flickity({
	cellAlign: 'center',
	bgLazyLoad: true,
	fade: true,
	prevNextButtons: false,
	pageDots: false,
	wrapAround: true,
	imagesLoaded: true,
	setGallerySize: false
});

$card.on('click', function(e){
	e.preventDefault();

	$body.addClass('stopScrolling');

	$this = $(this);
	$this.addClass('active');
	slideno = $this.data('slideno');
	$imageGallery.flickity( 'select', slideno );
	$galleryOverlay.fadeIn(200);
	$imageGallery.flickity('resize');

});

$galleryPrev.on('click', function(e) {
	e.preventDefault();
	$imageGallery.flickity('previous', true);
});

$galleryNext.on('click', function(e) {
	e.preventDefault();
	$imageGallery.flickity('next', true);

});

$closeGalleryOverlay.on('click', function(){
	$galleryOverlay.fadeOut(200);
	$body.removeClass('stopScrolling');
});
