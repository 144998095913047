
var $playVideo = $('#playButton'),
    $videoOverlay = $('#videoOverlay'),
    $closeVideoOverlay = $('#closeVideoOverlay'),
    videoType,
    videosrc;

	if($playVideo.length){
		
		$playVideo.on('click', function(e){
			e.preventDefault();
			
			videosrc = $(this).data('src'); console.log(videosrc);
						
			$("#videoOverlay iframe").attr('src', videosrc);
			$videoOverlay.fadeIn();
		
		});
		
		$closeVideoOverlay.on('click', function(e){
		    e.preventDefault();
		    $videoOverlay.fadeOut();
		    $("#videoOverlay iframe").attr('src', '');
		});
	
	}
 