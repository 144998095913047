var projectSliders = document.querySelectorAll(".js-project-slider");
console.log(projectSliders)

projectSliders.forEach((slider) => {
    const flkty = new Flickity(slider, {
        cellAlign: "center",
        prevNextButtons: false,
        pageDots: true,
        imagesLoaded: true,
        setGallerySize: false,
        resize: true,
        wrapAround: true
    });

    const projectSliderParent = slider.parentNode;

    const prevButton = projectSliderParent.querySelector(".js-project-previous");
    const nextButton = projectSliderParent.querySelector(".js-project-next");

    prevButton.addEventListener("click", (e) => {
        e.preventDefault();
        flkty.previous();
    });

    nextButton.addEventListener("click", (e) => {
        e.preventDefault();
        flkty.next();
    });

})