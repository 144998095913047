(function($){
	
	$.fn.rhLazy=function(options){
		
		var settings = $.extend({ 
			scrollThreshold:100,
			ajax:false
		},options);
		
				
		function offset(el) {
		    var rect = el.getBoundingClientRect();
		    
		    if (settings.ajax === false) {
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			} else {
				var ajaxOverlay = document.getElementById('ajaxOverlay');
				scrollTop = ajaxOverlay.scrollTop;
			}

		    return { top: rect.top + scrollTop }
		}

		
			var lazyloadImages = document.querySelectorAll("img.lazy");    
			var lazyloadThrottleTimeout;
			
			lazyload();
		  
			function lazyload() { 
				
				lazyloadImages = document.querySelectorAll("img.lazy"); 
			  
			    if(lazyloadThrottleTimeout) {
			      clearTimeout(lazyloadThrottleTimeout);
			    }    
		    
			    lazyloadThrottleTimeout = setTimeout(function() {
			     	if (settings.ajax === false) {
						var scrollTop = window.pageYOffset;
					} else {
						var ajaxOverlay = document.getElementById('ajaxOverlay');
						scrollTop = ajaxOverlay.scrollTop;
					}
						       
			        lazyloadImages.forEach(function(img) {

						var divOffset = offset(img);

			            if(divOffset.top < (window.innerHeight + scrollTop + settings.scrollThreshold)) {
			              img.src = img.dataset.src;
			              img.classList.remove('lazy');
			            }
			        });
			        if(lazyloadImages.length == 0) { 
			          document.removeEventListener("scroll", lazyload);
			          window.removeEventListener("resize", lazyload);
			          window.removeEventListener("orientationChange", lazyload);
			        }
			    }, 20);
		    
			}
 
			if (settings.ajax === false) {
				document.addEventListener("scroll", lazyload);
			} else {
				document.getElementById('ajaxOverlay').addEventListener("scroll", lazyload);
			}
			
			window.addEventListener("resize", lazyload);
			window.addEventListener("orientationChange", lazyload);
		
	}
}(jQuery));	

  

