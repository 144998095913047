//Use class flickity init on each slider

	$('.flickityInit').each( function() {

		var $this = $(this);

		initCarousels($this);

	});


	function initCarousels($this) {

		var $leCarousel = $this.find('.workCarousel');

		$leCarousel.on( 'ready.flickity', function() {
		 	$('.workCarousel').animate({'opacity': 1}, 1000);
		});

		$leCarousel.flickity({
		  cellAlign: 'center',
			bgLazyLoad: true,
			fade: true,
			prevNextButtons: false,
			pageDots: false,
			wrapAround: true,
			imagesLoaded: true,
			setGallerySize: false

		});


		var flkty = $leCarousel.data('flickity');

		function updateStatus() {

			var cellNumber = flkty.selectedIndex + 1;
			//console.log(cellNumber);
			var totalSlides = flkty.slides.length;
			$this.find('.currentSlide').text(cellNumber);
			$this.find('.totalSlides').text(totalSlides);
		}

		//updateStatus();

		$leCarousel.on( 'select.flickity', updateStatus );

		$this.find('.workInfoBtn').on('click', function(e){
			e.preventDefault();
			$leCarousel.flickity('select', 1);
		});

		$this.find('.ctrls.prev').on('click', function(e) {
			e.preventDefault();
			$leCarousel.flickity('previous', true);
		});

		$this.find('.ctrls.next').on('click', function(e) {
			e.preventDefault();
			$leCarousel.flickity('next', true);
		});


		// $leCarousel.on( 'dragStart.flickity', function( event, pointer ) {
		//    document.ontouchmove = function (e) {
		//        e.preventDefault();
		//    }
		// });
		//
		// $leCarousel.on( 'dragEnd.flickity', function( event, pointer ) {
		//    document.ontouchmove = function (e) {
		//        return true;
		//    }
		// });


		$this.removeClass('flickityInit');

	}
