(function($){
	
	$.fn.rhLoadIn=function(options){
		
		var settings = $.extend({ 
				scrollThreshold:50,
				ajax:false
			},options);
				
		function offset(el) {
		    var rect = el.getBoundingClientRect();
		    
		    if (settings.ajax === false) {
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			} else {
				var ajaxOverlay = document.getElementById('ajaxOverlay');
				scrollTop = ajaxOverlay.scrollTop;
			}

		    return { top: rect.top + scrollTop }
		}

		var rhLoadInElements = document.querySelectorAll(".loadIn"), 
			rhLoadThrottleTimeout;
		
		loadInScroll();
		
		function loadInScroll() {
			
			rhLoadInElements = document.querySelectorAll(".loadIn"); 
	
			if(rhLoadThrottleTimeout) {
		      clearTimeout(rhLoadThrottleTimeout);
		    }		    
		    
		    rhLoadThrottleTimeout = setTimeout(function() {
			    
		    	if (settings.ajax === false) {
					var scrollTop = window.pageYOffset;
				} else {
					var ajaxOverlay = document.getElementById('ajaxOverlay');
					scrollTop = ajaxOverlay.scrollTop;
				}
		      		        
		        rhLoadInElements.forEach(function(el) {

					var divOffset = offset(el);

		            if(divOffset.top < (window.innerHeight + scrollTop + settings.scrollThreshold)) {
		            	
					el.classList.add('isVisible');
					
					setTimeout(function(){ 
						el.classList.remove('loadIn');
					}, 600);
					
		            }
		        });
		        
		        if(rhLoadInElements.length == 0) { 
		          document.removeEventListener("scroll", loadInScroll);
		          window.removeEventListener("resize", loadInScroll);
		          window.removeEventListener("orientationChange", loadInScroll);
		        }
		        
		    }, 20);
	    
		}
 
		if (settings.ajax === false) {
			document.addEventListener("scroll", loadInScroll);
		} else {
			document.getElementById('ajaxOverlay').addEventListener("scroll", loadInScroll);
		}
		window.addEventListener("resize", loadInScroll);
		window.addEventListener("orientationChange", loadInScroll);

	
}

}(jQuery));	




