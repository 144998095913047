// @codekit-prepend "global/vars.js";
// @codekit-prepend "components/objectFit.js";
// @codekit-prepend "components/rhLazy.js";
// @codekit-prepend "components/LoadIn.js";
// @codekit-prepend "components/project-slider.js";
// @codekit-prepend "components/contentBuilder.js";
// @codekit-prepend "components/imageGallery.js";
// @codekit-prepend "components/multipleImageGalleries.js";
// @codekit-prepend "components/rhBanner.js";
// @codekit-prepend "components/rhparallax.js";
// @codekit-prepend "components/videoPop.js";
// @codekit-prepend "components/lazy.js";
// @codekit-prepend "components/debounce.js";

//on ready
$(document).ready(function(){


	$('body').rhLazy({
		scrollThreshold:100
	});
	
	var $splashWrapper = $('#splashWrapper');
	if ($splashWrapper.length > 0) {
		
		$body.addClass('stopScrolling');
		$splashWrapper.addClass('active');
		
		window.onload = function(){
			$splashWrapper.delay(1200).fadeOut(400,function(){
				$(this).remove();
				$body.removeClass('stopScrolling');
			});
		};

	}
	
	
/*
	$body.rhLoadIn();

	$('.parallax').rhBanner({
		parallaxRatio:1.4
	});
	
	$('.moveIt').rhParallax();
*/

var $mobileMenuButton = $('#menuButton'),
    $mobileMenu = $('#mobileMenu'),
    isActive = false;


$mobileMenuButton.on('click', function(e){
    console.log('click')
	e.preventDefault();
	
	$mobileMenuButton.addClass('working');
	
	isActive = !isActive;

	if (isActive) {
		openMenuAnimations();
	} else {
		closeMenuAnimations();
	}
	
	setTimeout(function(){ 
		$mobileMenuButton.removeClass('working');
	}, 200);

});

//functions
function openMenuAnimations() {
	
	$body.addClass('stopScrolling');
	$mobileMenuButton.addClass('active');
		
	TweenMax.to($mobileMenu, 0.3, {autoAlpha: 1});
		
}

function closeMenuAnimations() {

	$mobileMenuButton.removeClass('active');
	$body.removeClass('stopScrolling');
	
	TweenMax.to($mobileMenu, 0.3, {autoAlpha:0});
	
}


var $openSocials = $('#openSocials'),
	$closeSocials = $('#closeSocials'),
	$socialOverlay = $('#socialOverlay');
	
	
$openSocials.on('click', function(e){
	e.preventDefault();
	
	$body.addClass('stopScrolling');
	TweenMax.to($socialOverlay, 0.3, {autoAlpha: 1});

});

$closeSocials.on('click', function(e){
	e.preventDefault();
	
	$body.removeClass('stopScrolling');
	TweenMax.to($socialOverlay, 0.3, {autoAlpha: 0});

});



}); // end doc ready



// @codekit-append "global/resize.js";


