
//video
var $videoPlaceHolder = $('.videoPlaceHolder');

if($videoPlaceHolder.length){
	
	$videoPlaceHolder.click( function(e) {
		e.preventDefault();
	
		// console.log('clock deImage click');
	
		var videoSource = $(this).data('src'),
			$this = $(this);
	
		$this.next('iframe').attr('src', videoSource);
	
		$this.fadeOut();
	
	});

}