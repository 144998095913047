    // sets the treshold for when to fire lazy script, default is 500px below viewport
    let threshold = 500;

    // leave blank if using the documunent window, only specify for scrolling a particular element
    let scrollElement;
    
    lazyLoad(threshold, scrollElement);

function lazyLoad(threshold, scrollElement) {

    // if threshold isn't set then default to 500
    threshold = typeof threshold !== "undefined" ? threshold : 500;
   
    // if scrollElement is undefined set to window object, otherwise cache the selector
    scrollElement = typeof scrollElement !== "undefined" ? document.querySelector(scrollElement) : window;
    
    // wait for initial page layout before firing, then load images already in the viewport
    setTimeout(function(){ 
        lazyLoop(threshold, scrollElement); 
    }, 300);

    // run the loop on page resize
    window.addEventListener("resize", function () {
        debounce(lazyLoop(threshold, scrollElement));
    }, false );

    //run the loop on orientation change
    window.addEventListener("orientationChange", function () {
        debounce(lazyLoop(threshold, scrollElement));
    }, false );

    // add event listener to either the window or a specifi element scroll
    if (scrollElement == window) {
        window.addEventListener("scroll", function () {
            debounce(lazyLoop(threshold, scrollElement));
        }, false );
    } else {
        scrollElement.addEventListener( "scroll", function () { 
            debounce(lazyLoop(threshold, scrollElement));
        }, false );
    }
   
}


function lazyLoop(threshold, scrollElement) {

    let lazyScrollPos, 
        lazyloadImages = document.querySelectorAll(".lazy-img"),
        lazyloadVideos = document.querySelectorAll(".lazy-vid");

    // get current scroll position releative to either the windowm or the target element
    if (scrollElement === window) {
        lazyScrollPos = window.pageYOffset;
    } else {
        lazyScrollPos = scrollElement.scrollTop;
    }

    if (lazyloadImages.length > 0) {   
        lazyloadImages.forEach(function (el) {

            // get the target image
            let lazyImage = el.getElementsByTagName("img");

            // get target image top position
            let divOffset = offset(lazyImage[0], scrollElement);

            // if the position is less that that scroll position plus the threshold
            if (divOffset.top < window.innerHeight + lazyScrollPos + threshold) {
                
                // swap the source for the data-source
                //lazyImage[0].src = lazyImage[0].dataset.src;

                // swap the srcset for the data-srcset
                lazyImage[0].srcset = lazyImage[0].dataset.srcset;

                // swap the sizes for the data-sizes
                lazyImage[0].sizes = lazyImage[0].dataset.sizes;
                
                // set up listener for image load
                imagesLoaded( lazyImage, function( ) {

                    // swap classes to fade image in and remove lazy class so image is romved from lazy object
                    el.classList.add("lazy-img--loaded");
                    el.classList.remove("lazy-img");

                    // remove the data attributes
                    lazyImage[0].removeAttribute('data-srcset');
                    lazyImage[0].removeAttribute('data-sizes');

                });
               
            }
        });
    }

    if (lazyloadVideos.length > 0) {   
        lazyloadVideos.forEach(function (el) {

            let videoElement = el.getElementsByTagName("video")[0];

            // get target image top position
            let divOffset = offset(videoElement, scrollElement);

            // if the position is less that that scroll position plus the threshold
            if (divOffset.top < window.innerHeight + lazyScrollPos + threshold) {

                // gert the source from inside the video element
                let videoSource = videoElement.getElementsByTagName("source")[0];

                // swap the source for the data-source
                videoSource.src = videoSource.dataset.src;
                
                // load thevideo
                videoElement.load();
                
                // add listener to fade in video when playback is possible
                videoElement.addEventListener('canplay', videoReady);

            }
            
        });
    }

}

function videoReady(){ 
    this.classList.add('lazy-vid__video--ready');
    this.parentNode.classList.remove('lazy-vid');
    this.getElementsByTagName("source")[0].removeAttribute('data-src');
}

function offset(lazyImage, scrollElement) {

    // element top is relative to viewport, not document - this fixed that
    let currentScrollPos, rect = lazyImage.getBoundingClientRect();

    if (scrollElement === window) {
        currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;
    } else {
        currentScrollPos = scrollElement.scrollTop;
    }

    return { top: rect.top + currentScrollPos };
}
