


(function($){
	
	$.fn.rhParallax=function(options){
		
		var settings = $.extend({ 
			speed:0.4,
			ajax:false,
			selector:'body'
		},options);
		
		// Detect css transform
		var cssTransform = (function(){
		    var prefixes = 'transform webkitTransform mozTransform oTransform msTransform'.split(' '), 
		    	cssTransform, 
		    	i = 0
				while( cssTransform === undefined ){ 
		       		cssTransform = document.createElement('div').style[prefixes[i]] != undefined ? prefixes[i] : undefined
			   		i++
				}
		     return cssTransform;
		    
		})() 
		
		// Detect request animation frame
		var rhParallaxScroll = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || window.oRequestAnimationFrame || function(callback){ window.setTimeout(callback, 1000/60) }
		
		
		
	function parallaxElement(el, height, top, bottom, middle, speed ) {
		this.el = el;
		this.height = height;
		this.top = top;
		this.bottom = bottom;
		this.middle = middle;
		this.speed = speed;
	}

	// set up vars
	var rhParallaxElements = this,
		rhParallaxSize = rhParallaxElements.length,
		matrix = [],
		lastPosition,
		wHeight,
		wHalfHeight,
		pause = false;
			
	function rhParallaxSizes(){
	    lastPosition = -1;
	    wHeight = window.innerHeight;
	    wHalfHeight = wHeight/2;
	    var i = 0;
 
 	    for (i =0; i<rhParallaxSize; i++){
		    
	        var el = rhParallaxElements[i];
	        var elHeight = el.offsetHeight;
	        var elHalfHeight = elHeight/2;
	        
	        var elSpeed;
	        if (el.dataset.speed) {
		        elSpeed = el.dataset.speed;
	        } else {
		        elSpeed = settings.speed;
	        }
			
			var rect = el.getBoundingClientRect();
			var elTop;
			
			if (settings.ajax === false) {  
				elTop = rect.top + window.pageYOffset;
			} else {  
				elTop = rect.top;
			}
					
			var elBottom = elTop + elHeight;
			var elMiddle = elTop + elHalfHeight;
			
			matrix.push(
				new parallaxElement(el, elHeight, elTop, elBottom, elMiddle, elSpeed)
			);

	    }
	    
	}
	
	function rhParallaxResize() {
		//matrix = [];
		pause = true;
		lastPosition = -1;
	    wHeight = window.innerHeight;
	    wHalfHeight = wHeight/2;
	    var i = 0;
 
		$('.parallaxElement').css({"opacity":"0", "transform":"translateY(0)"});
		
	    matrix = [];

	    for (i =0; i<rhParallaxSize; i++){
		    
	        var el = rhParallaxElements[i];
	       
	        var elHeight = el.offsetHeight;
	        var elHalfHeight = elHeight/2;
	        
	        var elSpeed;
	        if (el.dataset.speed) {
		        elSpeed = el.dataset.speed;
	        } else {
		        elSpeed = settings.speed;
	        }
			
			var rect = el.getBoundingClientRect();
			var elTop;
			
			if (settings.ajax === false) {  
				elTop = rect.top + window.pageYOffset;
			} else {  
				elTop = rect.top;
			}
			
			var elBottom = elTop + elHeight;
			var elMiddle = elTop + elHalfHeight;
			
			matrix.push(
				new parallaxElement(el, elHeight, elTop, elBottom, elMiddle, elSpeed)
			);
			
	    }
	    pause = false;

	}
	
	$(window).resize(function() {
		rhParallaxResize();
	});
	
	// Lets Do This
	rhParallaxSizes();
	loop();
		
	function setTop(m, t) {
	    if (cssTransform) {
	        m.style[cssTransform] = "translate3d(0, "+ t +"px,0)"; 
	    } else {
	        m.style["top"] = t;
	    }
	}
	
	function loop(){ 
		
		var parallaxScrollPos;
		
		if (settings.ajax === false) { 
			parallaxScrollPos = window.pageYOffset;
		} else { 
			parallaxScrollPos = $(settings.selector).scrollTop(); console.log(settings.selector);
		}


			// Avoid calculations if not needed
		    if ((pause == true) || (lastPosition == parallaxScrollPos)) {
			   // console.log('skip');
		        rhParallaxScroll(loop)
		        return false
		        
		    } else { 
			   // console.log('run');
				
				if (settings.ajax === false) {
					lastPosition = window.pageYOffset
				} else {
					lastPosition = $(settings.selector).scrollTop();
				}

					
			    var i = 0
			    for (i =0; i<rhParallaxSize; i++){
			        // Is it visible right now?        
			       
			         if (lastPosition >= matrix[i].top - wHeight && lastPosition <= matrix[i].bottom) {
			          	// Do something				
						
						var scrolllOffset;
						if (matrix[i].top >= wHeight) {
							scrolllOffset = 0;
						} else {
							scrolllOffset = matrix[i].speed * (matrix[i].middle - wHalfHeight);
						}
						
						var scrollDistance = matrix[i].speed * (matrix[i].middle - lastPosition - wHalfHeight);
						setTop(matrix[i].el, scrollDistance - scrolllOffset)  
						matrix[i].el.style.opacity = 1;
						
						//console.log(matrix[i].el);
		                 
		        	} 
		        
			    }
			   
			    rhParallaxScroll(loop)
			    
		    }
				    
	} // end loop

		
			
	}

}(jQuery));	

	
	
	
	
	


 
	

	
