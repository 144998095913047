$(window).resize(function() {

  if ( $(window).width() != width ) {
    //console.log('width has changed');

  }

  //height = $(window).height();
  width = $(window).width();






});
